import * as React from "react";
import * as ReactDOM from "react-dom";

import { Icon } from "@iconify/react";

import EnquiryCardComponent from "./EnquiryCommon/EnquiryCard";
import EnquiryProfileComponent from "./EnquiryCommon/EnquiryProfile";
import EnquiryCommentsComponent from "./EnquiryCommon/EnquiryComments";
import EnquiryAbout from "./EnquiryCommon/EnquiryAbout";
import EnquiryReview from "./EnquiryCommon/EnquiryReview";
import EnquiryCreateAccount from "./EnquiryCommon/EnquiryCreateAccount";
import type * as Types from "./../NewEnquiry/context/EnquiryTypes";
import AddFriendComponent from "./ReferFriend/AddFriend";
import AddMessageComponent from "./ReferFriend/AddMessage";
import ReferFriendThanksComponent from "./ReferFriend/ReferFriendThanks";
import EnquiryFormSubmitComponent from "./ShareFriend/EnquiryFormSubmit";
import EnquiryShareCardComponent from "./ShareFriend/EnquiryShareCard";

import ReferFriendReview from "./ReferFriend/ReferFriendReview";
import EnquiryCareOption from "./LiveTourBooking/EnquiryCareOption";
import EnquiryDateOption, {
    EnquiryDateOptionComponent,
} from "./LiveTourBooking/EnquiryDateOption";
import EnquiryLiveTourReview from "./LiveTourBooking/EnquiryLiveTourReview";
import LiveTourFormSubmitComponent from "./ShareFriend/LiveTourFormSubmit";
import EnquireSaveDetail from "./ShareFriend/EnquireSaveDetail"
import CardCampaign from "./partial/CampainCard"

export const ENQUIRY_STATE = {
    ABOUT: "ABOUT",
    PROFILE: "PROFILE",
    CREATE_ACCOUNT: "CREATE_ACCOUNT",
    LIVE_TOUR_CARE_OPTION: "LIVE_TOUR_CARE_OPTION",
    LIVE_TOUR_DATE_OPTION: "LIVE_TOUR_DATE_OPTION",
    LIVE_TOUR_REVIEW: "LIVE_TOUR_REVIEW",
    LIVE_TOUR_SHARE: "LIVE_TOUR_SHARE",
    COMMENTS: "COMMENTS",
    REVIEW: "REVIEW",
    SHARE: "SHARE",
    REFER_FRIEND_ADD: "REFER_FRIEND_ADD",
    REFER_FRIEND_MESSAGE: "REFER_FRIEND_MESSAGE",
    REFER_FRIEND_REVIEW: "REFER_FRIEND_REVIEW",
    REFER_FRIEND_THANKS: "REFER_FRIEND_THANKS",
    SAVE_DETAILS: 'SAVE_DETAILS',
    SAVE_DETAILS_LIVE_BOOKING: 'SAVE_DETAILS_LIVE_BOOKING'
};
const BACK_BUTTON = {
    ABOUT: "ABOUT",
    PROFILE: "ABOUT",
    CREATE_ACCOUNT: "PROFILE",
    COMMENTS: "PROFILE",
    REVIEW: "COMMENTS",
    SHARE: "SHARE",
    LIVE_TOUR_CARE_OPTION: "PROFILE",
    LIVE_TOUR_DATE_OPTION: "LIVE_TOUR_CARE_OPTION",
    LIVE_TOUR_REVIEW: "LIVE_TOUR_DATE_OPTION",
    LIVE_TOUR_SHARE: "LIVE_TOUR_SHARE",
    REFER_FRIEND_ADD: "REFER_FRIEND_ADD",
    REFER_FRIEND_MESSAGE: "REFER_FRIEND_ADD",
    REFER_FRIEND_REVIEW: "REFER_FRIEND_MESSAGE",
    REFER_FRIEND_THANKS: "REFER_FRIEND_THANKS",
    SAVE_DETAILS: 'SHARE',
    SAVE_DETAILS_LIVE_BOOKING: 'LIVE_TOUR_SHARE'
};
const ENABLE_VOD = true

declare var c4kCore: any;

interface IEnquiryV2Component {
    data: Types.modelObject;
    isOpen: boolean;
    initialCareCentre: Types.CareCenter;
    careCentres: Types.CareCenter[];
    isOpenLiveTourBooking: boolean;
}

export const EnquiryV2Component = (props: IEnquiryV2Component) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [openModalMui, setOpenModalMui] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [isFormSubmit, setIsFormSubmit] = React.useState(false);
    const [currentState, setCurrentState] = React.useState(ENQUIRY_STATE.ABOUT);
    const [previousState, setPreviousState] = React.useState(ENQUIRY_STATE.ABOUT);
    const [enquiryState, setEnquiryState] = React.useState({
        about: [],
        profile: {},
        comments: "",
        createAccount: {},
        referFriendModal: {},
        liveTourAvailData: {},
        formSubmitData: {},
    });
   

    const closeModalPopup = () => {
        setOpenModalMui(false)
    }

    const [data, setData] = React.useState<any>({});
    const [enableDoorDash, setEnableDoorDash] = React.useState(false)
    /* 
      --------------------
        Hooks
      ---------------------
    */
    React.useEffect(() => {
        if (!open) {
            setEnquiryState({
                about: [],
                profile: {},
                comments: "",
                createAccount: {},
                referFriendModal: {},
                liveTourAvailData: {},
                formSubmitData: {},
            });
        }

    }, [isOpen]);

    React.useEffect(() => {

        if (props.data) {
            setData(data);
        }

        if (props.isOpen && props.isOpenLiveTourBooking) {
            sendGa4("PROFILE")
            setCurrentState(ENQUIRY_STATE.PROFILE)
            setPreviousState(ENQUIRY_STATE.ABOUT)
            setEnquiryState((prev) => ({
                ...prev,
                about: [
                    {
                        "label": "Live Tour Booking",
                        "id": 4,
                        "steps": 3,
                        "isActive": true,
                        "icon": "material-symbols:tour-outline"
                    }
                ],
            }));
            openModal()
            return
        }

        if (props.isOpen) {
            sendGa4("start");
            setCurrentState(ENQUIRY_STATE.ABOUT);
            setPreviousState(ENQUIRY_STATE.ABOUT)
            openModal();
        }

    }, [props]);

    /* 
      --------------------
        Handlers function
      ---------------------
    */
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {


        if (currentState === ENQUIRY_STATE.SAVE_DETAILS || currentState === ENQUIRY_STATE.SAVE_DETAILS_LIVE_BOOKING) {
            setOpenModalMui(true)
        }
        else {
            sendGa4("close");
            setIsOpen(false);
        }
    };

    const previous = () => {
        setCurrentState(BACK_BUTTON[currentState]);
    };
    const onNext = (payload: any) => {
        // console.log({ payload: payload })

        if (payload.currentState === ENQUIRY_STATE.ABOUT) {
            setEnquiryState({
                ...enquiryState,
                about: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.PROFILE) {
            setEnquiryState({
                ...enquiryState,
                profile: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.COMMENTS) {
            setEnquiryState({
                ...enquiryState,
                comments: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.CREATE_ACCOUNT) {
            setEnquiryState({
                ...enquiryState,
                createAccount: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.REVIEW) {
            setEnquiryState({
                ...enquiryState,
                formSubmitData: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.LIVE_TOUR_REVIEW) {
            setEnquiryState({
                ...enquiryState,
                formSubmitData: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.REFER_FRIEND_ADD) {
            setEnquiryState({
                ...enquiryState,
                referFriendModal: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.REFER_FRIEND_MESSAGE) {
            setEnquiryState({
                ...enquiryState,
                referFriendModal: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.REFER_FRIEND_THANKS) {
            closeModal();
        } else if (payload.currentState === ENQUIRY_STATE.LIVE_TOUR_CARE_OPTION) {
            setEnquiryState({
                ...enquiryState,
                liveTourAvailData: payload.data,
            });
        } else if (payload.currentState === ENQUIRY_STATE.LIVE_TOUR_DATE_OPTION) {
            setEnquiryState({
                ...enquiryState,
                liveTourAvailData: payload.data,
            });
        }

        setPreviousState(payload.currentState);
        setCurrentState(payload.nextState);

        sendGa4(payload.currentState);
    };

    const sendGa4 = (state: any) => {
        let eventSuburb = `${props.data.enquiryCentreDetailsList[0].centreSuburb}, ${props.data.enquiryCentreDetailsList[0].centreState}, ${props.data.enquiryCentreDetailsList[0].centrePostcode}`;
        let eventLabel = `type=start|source=CLP`;
        let eventListingType = props.data.enquiryCentreDetailsList[0].listingType;

        switch (state) {
            case "start":
                eventLabel = `type=start|source=CLP`;
                break;
            case "close":
                eventLabel = `type=close|source=CLP`;
                break;
            case "ABOUT":
                eventLabel = `type=general-enquiry|source=CLP`;
                break;
            case "LIVE_TOUR_CARE_OPTION":
                eventLabel = `type=live-tour-booking|source=CLP`;
                break;
            case "PROFILE":
                eventLabel = `type=parent-details|source=CLP`;
                break;
            case "COMMENTS":
                eventLabel = `type=review|source=CLP`;
                break;
            case "REVIEW":
                eventLabel = `type=submit|source=CLP`;
                break;
            case "LIVE_TOUR_REVIEW":
                eventLabel = `type=submit|source=CLP`;
                break;
            case "":
                eventLabel = `type=|source=CLP`;
                break;
        }

        eventLabel += `|${props.data.enquiryCentreDetailsList[0].eventContext}`;

        c4kCore.analyticsTrackEvent(
            "Enquiry",
            state.toLowerCase(),
            eventLabel,
            eventSuburb,
            eventListingType
        );
    };

    /* 
     --------------------
       Sectional function
     ---------------------
   */

    const CardComponent = () => {
        return (
            <EnquiryCardComponent
                initialCareCentre={props.initialCareCentre}
                careCentres={props.careCentres}
            />
        );
    };

    const renderComponent = () => {
     
        switch (currentState) {
            case ENQUIRY_STATE.ABOUT:
                return (
                    <React.Fragment>
                        <EnquiryAbout
                            isLiveTourBookingAvailable={
                                props.data.enquiryCentreDetailsList[0]
                                    ?.isLiveTourBookingAvailable
                            }
                            enquiryDetail={enquiryState}
                            onNext={onNext}
                        />
                        <CardComponent />
                    </React.Fragment>
                );

            case ENQUIRY_STATE.PROFILE:
                return (
                    <React.Fragment>
                        <EnquiryProfileComponent
                            isLoggedIn={props.data.isLoggedIn}
                            userInfo={props.data}
                            enquiryDetail={enquiryState}
                            isLiveTourBookingAvailable={
                                props.data.enquiryCentreDetailsList[0]
                                    ?.isLiveTourBookingAvailable
                            }
                            onNext={onNext}
                        />
                        <CardComponent />
                    </React.Fragment>
                );

            case ENQUIRY_STATE.CREATE_ACCOUNT:
                return (
                    <React.Fragment>
                        <EnquiryCreateAccount
                            enquiryDetail={enquiryState}
                            onNext={onNext}
                        />
                        <CardComponent />
                    </React.Fragment>
                );

            case ENQUIRY_STATE.COMMENTS:
                return (
                    <React.Fragment>
                        <EnquiryCommentsComponent
                            enquiryDetail={enquiryState}
                            onNext={onNext}
                        />
                        <CardComponent />
                    </React.Fragment>
                );

            case ENQUIRY_STATE.REVIEW:
                return (
                    <React.Fragment>
                        <EnquiryReview
                            centreUserIdHash={
                                props.data.enquiryCentreDetailsList[0]?.centreUserIdHash
                            }
                            url={props.data.enquiryRequestUrl}
                            enquiryDetail={enquiryState}
                            onNext={onNext}
                            setEnableDoorDash={(value) => {
                                setEnableDoorDash(value)
                            }}
                        />
                        <CardComponent />
                    </React.Fragment>
                );

            case ENQUIRY_STATE.SHARE:
                return (
                    <React.Fragment>
                        {true ? <React.Fragment>
                            <div className="flex w-full flex-col lg:flex-row lg:justify-between">
                                <div className="order-2 lg:order-1 pt-2.5 flex items-center">
                                    <EnquiryFormSubmitComponent
                                        onNext={onNext}
                                        initialCareCentre={props.initialCareCentre}
                                        careCentres={props.careCentres}
                                        enabledDoorDash={enableDoorDash}
                                        isLoggedIn={props.data.isLoggedIn}
                                        enquiryDetail={enquiryState}
                                        enquiryType={2}

                                    />
                                </div>

                                <div className="order-1 lg:order-1">
                                    {(ENABLE_VOD && props.initialCareCentre.isPaidCentre) ? <CardCampaign /> : false && enableDoorDash && (props.data.enquiryCentreDetailsList[0].centreState == "NSW" || props.data.enquiryCentreDetailsList[0].centreState == "SA")
                                        ? <EnquiryShareCardComponent enabledDoorDash={enableDoorDash} centreState={props.data.enquiryCentreDetailsList[0].centreState} onNext={onNext} /> 

                                        : <CardComponent />
                                    }
                                </div>
                            </div>
                        </React.Fragment> :
                            <React.Fragment>

                                <EnquiryFormSubmitComponent
                                    onNext={onNext}
                                    initialCareCentre={props.initialCareCentre}
                                    careCentres={props.careCentres}
                                    isLoggedIn={props.data.isLoggedIn}
                                    enquiryDetail={enquiryState}
                                    enquiryType={2}
                                />
                                {(ENABLE_VOD && props.initialCareCentre.isPaidCentre)  ? <CardCampaign/> :  false && enableDoorDash && (props.data.enquiryCentreDetailsList[0].centreState == "NSW" || props.data.enquiryCentreDetailsList[0].centreState == "SA")
                                    ? <EnquiryShareCardComponent enabledDoorDash={enableDoorDash} centreState={props.data.enquiryCentreDetailsList[0].centreState} onNext={onNext} />
                                    : <CardComponent />
                                }

                            </React.Fragment>
                        }


                    </React.Fragment>
                );

            case ENQUIRY_STATE.REFER_FRIEND_ADD:
                return <AddFriendComponent onNext={onNext} />;

            case ENQUIRY_STATE.REFER_FRIEND_MESSAGE:
                return (
                    <AddMessageComponent onNext={onNext} enquiryDetail={enquiryState} />
                );

            case ENQUIRY_STATE.REFER_FRIEND_REVIEW:
                return (
                    <ReferFriendReview onNext={onNext} enquiryDetail={enquiryState} />
                );

            case ENQUIRY_STATE.REFER_FRIEND_THANKS:
                return (
                    <ReferFriendThanksComponent
                        onNext={onNext}
                        enquiryDetail={enquiryState}
                    />
                );
            case ENQUIRY_STATE.LIVE_TOUR_CARE_OPTION:
                return (
                    <>
                        <EnquiryCareOption
                            token={props.data.token}
                            enquiryDetail={enquiryState}
                            onNext={onNext}
                        />
                        <CardComponent />
                    </>
                );
            case ENQUIRY_STATE.LIVE_TOUR_DATE_OPTION:
                return (
                    <>
                        <EnquiryDateOption
                            token={props.data.token}
                            onNext={onNext}
                            enquiryDetail={enquiryState}
                        />
                        <CardComponent />
                    </>
                );
            case ENQUIRY_STATE.LIVE_TOUR_REVIEW:
                return (
                    <>
                        <EnquiryLiveTourReview
                            data={props.data}
                            onNext={onNext}
                            enquiryDetail={enquiryState}
                            setEnableDoorDash={(value) => {
                                setEnableDoorDash(value)
                            }}
                        />
                        <CardComponent />
                    </>
                );
            case ENQUIRY_STATE.LIVE_TOUR_SHARE:
                return (
                    <React.Fragment>
                        <style>{`
                         
                            @media only screen and (max-width: 993px) {
                                .wrapper-live-tour-share {
                                    height: calc(100vh - 465px);
                                    overflow: auto;
                                }
                              
                            }
                        `}</style>
                        <div className="mt-[40px] flex flex-col lg:flex-row lg:justify-between w-full lg:wrapper-live-tour-share">
                            <div className="order-2 lg:order-1 pt-2.5 flex items-center">
                                <LiveTourFormSubmitComponent
                                    isLoggedIn={props.data.isLoggedIn}
                                    data={props.data}
                                    onNext={onNext}
                                    initialCareCentre={props.initialCareCentre}
                                    careCentres={props.careCentres}
                                    enquiryDetail={enquiryState}
                                    enquiryType={4}
                                />
                            </div>
                            <div className="order-1 lg:order-1 mt-[-40px] lg:mt-0">
                                {(ENABLE_VOD && props.initialCareCentre.isPaidCentre) ? <CardCampaign /> : false && enableDoorDash && (props.data.enquiryCentreDetailsList[0].centreState == "NSW" || props.data.enquiryCentreDetailsList[0].centreState == "SA")
                                    ? <EnquiryShareCardComponent enabledDoorDash={enableDoorDash} centreState={props.data.enquiryCentreDetailsList[0].centreState} onNext={onNext} />
                                    : <CardComponent />
                                }
                            </div>
                        </div>


                    </React.Fragment>
                );
            case ENQUIRY_STATE.SAVE_DETAILS:
                return <EnquireSaveDetail enquiryType={2} enquiryDetail={enquiryState} onNext={onNext} closeModal={() => {
                    sendGa4("close");
                    setIsOpen(false);
                }} />

            case ENQUIRY_STATE.SAVE_DETAILS_LIVE_BOOKING:
                return <EnquireSaveDetail enquiryType={4} enquiryDetail={enquiryState} onNext={onNext} closeModal={() => {
                    sendGa4("close");
                    setIsOpen(false);
                }} />
            default:
                return <></>;
        }
    };

    return (
        <div>
            {isOpen && (
                <div className="enquire-popup flex justify-center items-end lg:items-center active" style={{ zIndex: 499 }}>
                    <div className="rounded-20 bg-white shadow-small popup-container flex lg:gap-[17px] relative">
                        <button
                            className="close-btn  absolute top-[16px] flex items-center justify-center text-white"
                            onClick={closeModal}
                        >
                            <Icon icon="iconamoon:close" className="text-2xl z-5"></Icon>
                        </button>
                        {![
                            ENQUIRY_STATE.ABOUT,
                            ENQUIRY_STATE.SHARE,
                            ENQUIRY_STATE.REFER_FRIEND_ADD,
                            ENQUIRY_STATE.REFER_FRIEND_THANKS,
                            ENQUIRY_STATE.SAVE_DETAILS
                        ].includes(currentState) ? (
                                <button
                                    style={{top:'16px'}}
                                    className="back-btn absolute flex items-center justify-center"
                                onClick={previous}
                            >
                                <Icon
                                    icon="ic:baseline-arrow-back-ios-new"
                                    className="text-black-1 z-5"
                                ></Icon>
                            </button>
                        ) : (
                            <></>
                        )}
                        {renderComponent()}
                    </div>
                </div>
            )}

            {openModalMui &&
                <div className="enquire-popup flex justify-center items-end lg:items-center active">
                    <div className="rounded-20 bg-white shadow-small popup-container flex lg:gap-85 relative">
                        <button
                            className="close-btn absolute flex items-center justify-center text-white"
                            onClick={closeModalPopup}
                        >
                            <Icon icon="iconamoon:close" className="text-2xl z-5"></Icon>
                        </button>
                        <div className="flex flex-col relative items-center w-full pt-3 lg:pt-0 m-auto">
                            <div className="font-bold text-[26px] text-[#272727] text-center">Are you sure?</div>
                            <div className="font-medium text-[17px] text-[#272727] text-center">Your details will not be saved if you cancel.</div>
                            <div className="flex flex-col lg:flex-row lg:justify-between w-full items-center gap-4 mt-10">
                                <button
                                    className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-[#5A60EC]"
                                    style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', border: '1.71px solid #5A60EC', height: '100%' }}
                                    onClick={() => {
                                        sendGa4("close");
                                        setIsOpen(false);
                                        setOpenModalMui(false);
                                    }}>
                                    Yes
                                </button>
                                <button
                                    className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-white"
                                    onClick={() => setOpenModalMui(false)}
                                    style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', height: '100%', backgroundColor: '#FF5A9A' }}
                                >
                                    No
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>
    );
};

(window as any).RenderEnquiryV2Component = function (props) {
    ReactDOM.render(
        <EnquiryV2Component {...props} />,
        document.getElementById("enquiryv2Component")
    );
};


import * as React from "react";
import { Icon } from '@iconify/react';
import { ENQUIRY_STATE } from "../EnquiryV2Component";

interface ILiveTourFormSubmitComponent {
    [key: string]: any
}

/* 
    --------------------
      Handlers function
    ---------------------
  */

const showCentreOptions = () => {

}


export const LiveTourFormSubmitComponent = (props: ILiveTourFormSubmitComponent) => {
    const { initialCareCentre, careCentres, data, enquiryDetail } = props;
    const { liveTourAvailData } = enquiryDetail;

    var activeModal = ""
    const isPaidCentre = initialCareCentre.isPaidCentre;
    const [isCentreNearbyProcessed, setIsCentreNearbyProceessed] = React.useState(false)
    const [dayName, setDayName] = React.useState('');
    const [pageStack, setPageStack] = React.useState([]);
    React.useEffect(() => {
        let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let date = new Date(enquiryDetail?.liveTourAvailData?.slot);
        let day = date.getDay();
        setDayName(daysOfWeek[day])
        if (props.careCentres?.length > 0) {
            setIsCentreNearbyProceessed(true);
        }
    }, [props])

    const checkEmailInvalid = () => {
        let formData = new FormData();
        formData.append('email', enquiryDetail.profile.email);


        fetch(`/parentaccount/validateemail`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('parentaccount/validateemail', data);
                if (!data) {
                    props.onNext({
                        currentState: ENQUIRY_STATE.LIVE_TOUR_SHARE,
                        nextState: ENQUIRY_STATE.SAVE_DETAILS_LIVE_BOOKING,
                    })
                }
                else {
                    const currentURL = window.location.href;
                    const containsChildCareProviders = currentURL.includes("/childcareproviders");
                    if (containsChildCareProviders) {

                        window.open(`${window.BaseUrl}/signin`, '_blank');
                    } else {
                        const currentURL = window.location.href;
                        const containsChildCareProviders = currentURL.includes("/childcareproviders");
                        let url = new URL(window.location.href);
                        let params = new URLSearchParams(url.search);

                        params.append('enquiry_id', props.enquiryDetail.formSubmitData.enquiryId);
                        window.history.replaceState({}, '', `${url.pathname}?${params}`);

                        localStorage.setItem('enquiry_type', props.enquiryType);
                        if (pageStack.length <= 0) {

                            setPageStack(prevStack => [...prevStack, "emailPage"]);
                        }

                        const authModal = document.getElementById('authModal');
                        if (authModal) {
                            authModal.classList.remove('hidden');
                        }
                    }
                }

            })
            .catch(error => {
                console.error(error);
            })
    }

    return (
        <>
            <style>
                {
                    `
                        .ico-check {
                            margin-right: auto;
                        }
                        @media screen and (max-width: 992px) {
                            .ico-check {
                                margin: auto;
                            }
                        }
                        
                    `
                }
            </style>
            <div className="flex flex-col items-center w-full lg:max-w-320 enquire-form-side">
                <div className="h-full w-full flex flex-col lg:justify-center items-center gap-2">
                    <div className="mt-5 lg:mt-0 w-full">
                        <Icon icon="teenyicons:tick-circle-solid"
                            className="text-3xl text-darkgreen ico-check mx-auto mt-[15px]"></Icon>
                        <h2 className="text-1xl font-bold text-black w-full text-center">
                            Congrats, your enquiry <br />has been submitted!
                        </h2>
                    </div>

                    {/* <span className="text-sm text-black-2">
                    {initialCareCentre.name} typically responds within
                    <span className="text-berry font-semibold"> few hours</span>
                </span> */}
                    <div className="center-detail-card w-full flex flex-col gap-2">
                        <div className="text-black-1 font-semibold text-sm">
                            <span > {data.enquiryCentreDetailsList[0]?.centreName},</span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon icon="ci:calendar-days"
                                className="text-sm text-berry"></Icon>
                            <span className="text-xs text-black-2">{dayName}, {liveTourAvailData.tourDate}</span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon icon="mdi:clock-outline"
                                className="text-sm text-berry"></Icon>
                            <span className="text-xs text-black-2">{liveTourAvailData.tourTime}</span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon icon="material-symbols:location-on-outline"
                                className="text-sm text-berry"></Icon>
                            <span className="text-xs text-black-2" >
                                {data.enquiryCentreDetailsList[0]?.centreAddress},
                                {data.enquiryCentreDetailsList[0]?.centreSuburb},
                                {data.enquiryCentreDetailsList[0]?.centreState},
                                {data.enquiryCentreDetailsList[0]?.centrePostcode}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2.5 mt-2 lg:mt-8 w-full">
                        {
                            !isCentreNearbyProcessed && careCentres && careCentres.length > 0 && !isPaidCentre &&
                            <button className=" enquire-next-btn bg-light-200 flex gap-1.5 items-center justify-center font-medium text-xs text-black" onClick={showCentreOptions}
                                style={{ marginTop: "0px" }}>
                                <Icon icon="ic:baseline-verified"
                                    className="text-berry text-xl"></Icon>
                                Enquire at Verified Centres nearby
                            </button>
                        }

                        <a href="/child-care-subsidy-calculator"
                            className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-[#5A60EC]"
                            style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', border: '1.71px solid #5A60EC', height: '100%' }}>
                            <Icon icon="ph:calculator"
                                className="text-berry text-xl"></Icon>
                            Calculate Childcare Subsidy
                        </a>
                        {!props.isLoggedIn && <button
                            onClick={() => checkEmailInvalid()}
                            className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-white"
                            style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', height: '100%', backgroundColor: '#FF5A9A' }}>
                            Save your details
                        </button>
                        }
                    </div>
                </div>
            </div>
        </>

    );
}

export default LiveTourFormSubmitComponent;
import * as React from "react";
import { Icon } from "@iconify/react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { env } from "../../../environment";
export interface EnquirySubmitModel {
    centreUserIdHash: string;
    enquiryType: number;
    recaptchaResult: string;
    parentFullName: string;
    email: string;
    contactNumber: string;
    bestTimeToCall: string;
    saveDetails: boolean;
    enquiryDetails: any;
    selectedVisitDays: any[];
    childrenDetails: ChildrenDetail[];
    additionalQuestions: any[];
    liveTourBookingSlot: string;
    token: string;
    includeRecommendCentresHtml: boolean;
    includeUpsellCentresHtml: boolean;
    centrePostcode: string;
    centreSuburbHashId: string;
}

export interface ChildrenDetail {
    name: string;
    birthday: string;
    careStartDate: string;
    selectedCareDays: number[];
    overnightCareRequired: boolean;
}

interface IEnquiryLiveTourReviewComponent {
    [key: string]: any;
}
export const EnquiryLiveTourReviewComponent = (
    props: IEnquiryLiveTourReviewComponent
) => {
    const { enquiryDetail, data } = props;
    // console.log('props=', props);

    const { profile, liveTourAvailData } = enquiryDetail;
    const [loader, setLoader] = React.useState(false);
    const [dayName, setDayName] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const recaptchaRef = React.createRef();
    const [isVerified, setIsVerified] = React.useState(false);
    const [recaptchaResult, setReCaptchaResult] = React.useState("");

    const HandleVerify = (token) => {
        if (token) {
            setReCaptchaResult(token);
            setIsVerified(true);
        } else {
            setIsVerified(false);
        }
    };

    React.useEffect(() => {
        let daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        let date = new Date(enquiryDetail?.liveTourAvailData?.slot);
        let day = date.getDay();
        setDayName(daysOfWeek[day]);
    }, []);

    /* 
      --------------------
        Handlers function
      ---------------------
    */
    const onSubmit = () => {
        // api call
        setLoader(true);
        setIsVerified(false)
        let payload: EnquirySubmitModel = enrichForEnquirySave();
        let headers = {
            "Content-Type": "application/json",
        };
        let url = data.enquiryRequestUrl;
        const json = JSON.stringify(payload);
        axios
            .post(url, json, { headers: headers })
            .then((response: any) => {
                if (response.data.isSuccess) {
                    let enquiryId = 0;
                    if (response?.data?.enquiryIds != null) {
                        var key = Object.keys(response?.data?.enquiryIds)[0];
                        enquiryId = response?.data?.enquiryIds[key];
                    }

                    props.setEnableDoorDash(response.data.enabledDoorDash || false)
                    let state = {
                        currentState: ENQUIRY_STATE.LIVE_TOUR_REVIEW,
                        nextState: ENQUIRY_STATE.LIVE_TOUR_SHARE,
                        data: { enquiryId: enquiryId },
                    };
                    //const countEnquiries = document.getElementById('count-enquiries')?.textContent;

                    //if (!isNaN(Number(countEnquiries))) {
                    //    const newCount = Number(countEnquiries) + 1;
                    //    document.getElementById('count-enquiries').textContent = newCount > 0 ? newCount.toString() : '1';
                    //}

                    props.onNext(state);
                    setLoader(false);
                    setIsVerified(true)
                } else {
                    setErrorMessage(response?.data?.errors[0]);
                    setLoader(false);
                    setIsVerified(true)
                }
            })
            .catch((err) => {
                setLoader(false);
                setErrorMessage(err.message);
                console.log(err);
            })
    };

    /* 
      --------------------
        Helper function
      ---------------------
      */
    const enrichForEnquirySave = () => {
        const updatedChildrenDetails =
            enquiryDetail.liveTourAvailData?.childrenDetails.map((child) => ({
                ...child,
                selectedCareDays: child.selectedCareDays.map(Number),
            }));
        return {
            centreUserIdHash: data.enquiryCentreDetailsList[0]?.centreUserIdHash,
            enquiryType: 4,
            recaptchaResult: recaptchaResult,
            parentFullName: `${profile.firstName} ${profile.lastName}`,
            email: profile.email,
            contactNumber: profile.phone,
            bestTimeToCall: "Anytime",
            saveDetails: profile.isSaveInfoChecked,
            enquiryDetails: null,
            selectedVisitDays: [],
            childrenDetails: updatedChildrenDetails,
            additionalQuestions: [],
            liveTourBookingSlot: enquiryDetail?.liveTourAvailData?.slot,
            token: data.token,
            includeRecommendCentresHtml: true,
            includeUpsellCentresHtml: true,
            centrePostcode: data.enquiryCentreDetailsList[0]?.centrePostcode,
            centreSuburbHashId: data.enquiryCentreDetailsList[0]?.centreSuburbIdHash,
        };
    };

    return (
        <div className="flex flex-col relative items-center w-full lg:max-w-320 pt-5 lg:pt-0 enquire-form-side">
            <div className="step-meter bg-gray relative rounded-25">
                <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-full"></span>
            </div>
            <div
                className="h-full w-full flex flex-col relative justify-between overflow-y-auto"
                style={{ height: "350px" }}
            >
                <div className="flex flex-col relative justify-between gap-4 pb-8">
                    <h3
                        className="font-bold text-lg text-black-1 leading-19p"
                        style={{ maxWidth: "240px" }}
                    >
                        Review Live Tour Booking
                    </h3>
                    <span className="text-sm text-red-500"> {errorMessage}</span>
                    <div className={`${errorMessage == "" ? "mt-m20p" : ""}`}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={env.GOOGLE_SITE_KEY}
                            onChange={(token) => HandleVerify(token)}
                        />
                    </div>
                    <div className="center-detail-card flex flex-col gap-2">
                        <div className="flex items-center gap-3">
                            <Icon
                                icon="ci:calendar-days"
                                className="text-sm text-berry"
                            ></Icon>
                            <span className="text-xs text-black-2">
                                {dayName}, {liveTourAvailData.tourDate}
                            </span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon
                                icon="mdi:clock-outline"
                                className="text-sm text-berry"
                            ></Icon>
                            <span className="text-xs text-black-2">
                                {liveTourAvailData.tourTime}
                            </span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon
                                icon="material-symbols:location-on-outline"
                                className="text-sm text-berry"
                            ></Icon>
                            <span className="text-xs text-black-2">
                                {data.enquiryCentreDetailsList[0]?.centreAddress},{" "}
                                {data.enquiryCentreDetailsList[0]?.centreSuburb}{" "}
                                {data.enquiryCentreDetailsList[0]?.centreState}{" "}
                                {data.enquiryCentreDetailsList[0]?.centrePostcode}
                            </span>
                        </div>
                    </div>

                    <div style={{ maxWidth: "240px" }}>
                        <h3 className="text-black-1 text-sm font-bold">Contact details</h3>
                        <div className="mt-3 flex flex-col gap-1.5">
                            <div className="grid grid-cols-3">
                                <span className="col-span-1 font-semibold text-xs text-black-1">
                                    Name
                                </span>
                                <span className="col-span-2 text-xs text-black-2">
                                    {profile.firstName + " " + profile.lastName}
                                </span>
                            </div>
                            <div className="grid grid-cols-3">
                                <span className="col-span-1 font-semibold text-xs text-black-1">
                                    Email
                                </span>
                                <span className="col-span-2 text-xs text-black-2">
                                    {profile.email}
                                </span>
                            </div>
                            <div className="grid grid-cols-3">
                                <span className="col-span-1 font-semibold text-xs text-black-1">
                                    Number
                                </span>
                                <span className="col-span-2 text-xs text-black-2">
                                    {profile.phone}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="captcha-container bg-light-200 flex items-center gap-3">
                        <div className="recaptcha" data-sitekey="@CareForKidsWebConfiguration.Current.GoogleRecaptchaSiteKey"></div>
                    </div> */}
                </div>
            </div>
            <div className="flex bottom-0 w-full flex-col gap-2">
                <button
                    className={`enquire-next-btn ${!isVerified
                        ? " btn-disabled hover:btn-disabled "
                        : " bg-rose hover:bg-darkrose "
                        } flex items-center justify-center font-semibold text-base text-white`}
                    onClick={onSubmit}
                    style={{ marginTop: 0 }}
                    disabled={!isVerified}
                >
                    {loader ?
                        <>
                            <img src="/img/navy-spinner.svg" alt="Spinner blue dots circle" height="28" width="28" style={{
                                mixBlendMode: 'multiply'
                            }} />
                            <span>Processing...</span>
                        </> : "Submit"}
                </button>
            </div>
        </div>
    );
};

export default EnquiryLiveTourReviewComponent;
